import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "about-lists",
      "style": {
        "position": "relative"
      }
    }}>{`About lists`}</h2>
    <p>{`Use lists to make content more readable and scannable.`}</p>
    <h2 {...{
      "id": "bulleted-unordered-lists",
      "style": {
        "position": "relative"
      }
    }}>{`Bulleted (unordered) lists`}</h2>
    <p>{`Use bulleted lists to to present items in a enumarting way.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=513%3A262" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "changing-the-bullet-icon",
      "style": {
        "position": "relative"
      }
    }}>{`Changing the bullet icon`}</h3>
    <p>{`In some cases the bullet `}<inlineCode parentName="p">{`Icon`}</inlineCode>{` can be replaced with another icon to convey more
meaning in specific situations.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=520%3A0" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "numbered-ordered-lists",
      "style": {
        "position": "relative"
      }
    }}>{`Numbered (ordered) lists`}</h2>
    <p>{`Use numbered (ordered) lists to present items in a sequential order.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=520%3A47" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "guidelines-when-writing-list-items",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines when writing list items`}</h2>
    <ul>
      <li parentName="ul">{`Capitalize each item's first letter.`}</li>
      <li parentName="ul">{`Use punctuation whenever an item is a complete sentence. Combining both types
of sentences is perfectly possible.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      